<template>
  <v-container fluid>
    <v-sheet class="mt-4">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="auto">
            <v-subheader>{{$_strings.termOfPayment.headerNomor}}<span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="12" sm="6" md="8">
            <v-text-field
              outlined
              dense
              v-mask="{'alias': 'integer', allowMinus: false, rightAlign: false}"
              v-model="form.top"
              :rules="rules.top"
              clearable
            > </v-text-field>
          </v-col>
          <v-col cols="auto">
            <v-btn
              elevation="2"
              color="primary"
              @click="submit">
              <b>{{ $_strings.common.SAVE }}</b>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              elevation="2"
              class="float-right"
              @click="$router.go(-1)"
            >
              <b>{{ $_strings.common.BACK }}</b>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-sheet>
  </v-container>
</template>

<script>

export default {
  name: 'term-of-payment-create',
  data() {
    return {
      valid: false,
      form: {
        top: '',
        isActive: true,
      },
      rules: {
        top: [
          (v) => {
            if (!v) return this.$_strings.messages.validation.REQUIRED(this.$_strings.termOfPayment.headerNomor);
            if (+v === 0) return 'Harus lebih dari 0';
            return true;
          },
        ],
      },
    };
  },
  computed: {
    topId() {
      return this.$route.params.id;
    },
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    topDetail() {
      this.$root.$loading.show();
      this.$_services.termOfPayment.topDetail(this.$route.params.id)
        .then((res) => {
          this.form = res.data;
        }).catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
          this.$router.go(-1);
        }).finally((e) => {
          this.$root.$loading.hide();
        });
    },
    submit() {
      if (!this.$refs.form.validate()) {
        this.$nextTick(() => {
          const el = this.$el.querySelector('.v-messages.error--text:first-of-type');
          this.$vuetify.goTo(el, { offset: 50 });
        });
        return;
      }
      this.$root.$loading.show();
      this.form.top = +this.form.top;
      this.$_services.termOfPayment.topCreateEdit(this.form, this.topId)
        .then((res) => {
          if (this.topId) {
            this.$dialog.notify.success(this.$_strings.termOfPayment.successEdit);
          } else {
            this.$dialog.notify.success(this.$_strings.termOfPayment.successCreate);
          }
          this.$router.go(-1);
        })
        .catch((err) => {
          if (err.data && err.data.message) {
            this.$dialog.notify.error(err.data.message);
          }
        })
        .finally(() => {
          this.$root.$loading.hide();
        });
    },
  },
  created() {
    if (this.$route.params.id) {
      this.topDetail();
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
